import React from "react";
import { Form } from "react-bootstrap";

const StaticDropdown = ({
  label,
  defaultSelectMessage,
  selectValues,
  isInvalid,
  invalidMessage,
  ...others
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>

      <Form.Select isInvalid={isInvalid} {...others}>
        <option value="">{defaultSelectMessage}</option>
        {selectValues.map((data, index) => (
          <option
            key={index}
            // disabled={data.value === ""}
            value={data.value}
          >
            {data.label}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {invalidMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default StaticDropdown;
