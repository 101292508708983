import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

// Components
import TextInput from "components/Forms/TextInput";
import SpinnerLoader from "components/Common/SpinnerLoader";
import BillingCompanyDropdown from "components/Forms/BillingCompanyDropdown";
import BillingSubCompanyDropdown from "components/Forms/BillingSubCompanyDropdown";
import BillingDepartmentDropdown from "components/Forms/BillingDepartmentDropdown";
import StaticDropdown from "components/Forms/StaticDropdown";
import CustomModal from "components/Modal/CustomModal";
import DangerAlert from "components/Message/DangerAlert";

import EditCancel from "./EditCancel";

// Data
import {
  staticbillingRegion,
  staticbillto,
} from "pages/Requests/AWS/StaticData/awsStaticData";

// Helper
import { getApiCall } from "helper/getApiCall";
import { postApiCall } from "helper/postApiCall";

// Validator
import { aiRequestValidate } from "../validator/aiRequestValidate";

// Import Icons
import backicon from "icons/back.svg";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import { AWSReducer, DefaultAWSData } from "../NewRequest/aws-reducer";
import { useTranslation } from "react-i18next";
import {
  cloudTypeData,
  llmModelAws,
  llmModelAzure,
  llmModelGcp,
  trueFalseData,
} from "static-contents/ai-request-data";
import { AI_REQUEST_RELOAD } from "redux/ai-request/aiRequestConstant";

const EditBox = ({ data, closeEditParent }) => {
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const idToken = userInfo.idToken;

  // API Get State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  //gsid
  const [gsid, setGsid] = useState("");

  // Form State

  const [billingdepartment, setBillingdepartment] = useState(
    data.billingdepartment
  );

  // Component State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);

  // choose directory
  const [directory, setDirectory] = useState(data?.directory);

  // Data Input Error
  const [dataInputError, setDataInputError] = useState({});

  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // API Form Submit State
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formSubmitModal, setFormSubmitModal] = useState(false);
  const [formSubmitRefUid, setFormSubmitRefUid] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  const closeEditCallback = useCallback((value) => {
    closeEditParent(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === "billingOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: "",
          [`billingDepartment`]: "",
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingOpco`]: opCompany,
        },
      });
      if (opCompany !== "") {
        const getSubOpCompany = responseData.find((t) => t.opco === opCompany)
          .subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === "billingSubOpco") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: "",
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingSubOpco`]: opSubCompany,
        },
      });
      if (opSubCompany !== "") {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }
    if (target.name === "billingDepartment") {
      let department = target.value;
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`billingDepartment`]: department,
        },
      });
    }
    if (target.name === "haveCloudAccount") {
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`haveCloudAccount`]: target.value ? true : false,
          [`dontHaveCloudAccount`]: target.value ? false : true,
        },
      });
    }
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: { [target.name]: target.value },
    });
  };

  const handleDataTextArea = (name, value) => {
    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: {
        [name]: value,
      },
    });
  };

  // Form Submit
  const handleContinue = (e) => {
    e.preventDefault();
    const formSubmitApiUrl = `${process.env.REACT_APP_AWS_WORKSPACES_ADD}?type=edit`;

    console.log("eeeeeeeee", formData?.aiRequestDetails);
    const errors = aiRequestValidate(formData?.aiRequestDetails);
    setDataInputError(errors);
    console.log("eeeeeeeee", errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setLoading(true);
      const currentEpochTime = Date.now();
      const formSubmitRequestBody = {};
      setFormSubmitRefUid(currentEpochTime);
      setFormSubmitModal(true);

      // postApiCall(formSubmitApiUrl, idToken, formSubmitRequestBody)
      //   .then((response) => {
      //     setFormSubmitRefUid(currentEpochTime);
      //     setFormSubmitModal(true);
      //   })
      //   .catch((err) => {
      //     setFormSubmitError(
      //       "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
      //     );
      //     setLoading(false);
      //   });
    }
  };

  useEffect(() => {
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
          "submittedBy": "${userInfo?.email}",
          "type_of_user": ${
            userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0
          }
        }`;
    const encodedString = Buffer.from(queryObj).toString("base64");
    // end

    const billingApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;
    getApiCall(billingApiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      let getSubOpCompany = [];
      if (data.billingOpco !== "") {
        setBillingSubOpCompany([]);
        setBillingDepartment([]);

        const checkOpco = response.data.body.find(
          (t) => t.opco === data.billingOpco
        );

        if (checkOpco === undefined) {
          setBillingSubOpCompany([]);
        } else {
          getSubOpCompany = response.data.body.find(
            (t) => t.opco === data.billingOpco
          ).subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
      }
      if (data.billingSubOpco !== "") {
        setBillingDepartment([]);

        const checkSubOpco = getSubOpCompany.find(
          (t) => t.subopco === data.billingSubOpco
        );

        if (checkSubOpco === undefined) {
          setBillingDepartment([]);
        } else {
          const getDepartment = getSubOpCompany.find(
            (t) => t.subopco === data.billingSubOpco
          ).dept;

          setBillingDepartment(getDepartment);
        }
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const makeFormData = [data]?.map((item) => item);

    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: makeFormData[0],
    });

    setFormData({
      type: "UPDATE_AI_REQUEST",
      payload: {
        [`haveCloudAccount`]: formData?.aiRequestDetails?.haveCloudAccount
          ? "true"
          : "false",
        [`dontHaveCloudAccount`]: formData?.aiRequestDetails?.haveCloudAccount
          ? "false"
          : "true",
      },
    });
  }, []);

  const uploadImage = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ["pdf", "xlsx", "docx"];
    const fileExtension = filePath.split(".").pop();

    if (checkFileSize >= 5120) {
      alert("File size should be below 5MB");
      ref.current.value = "";
    } else if (!allowedExtensions.includes(fileExtension)) {
      alert("file type not allowed");
      ref.current.value = "";
    } else {
      const base64 = await convertBase64(file);
      setFormData({
        type: "UPDATE_AI_REQUEST",
        payload: {
          [`supportingDocumentName`]: file.name,
          [`documentBaseFile`]: base64,
        },
      });
      setFileLoading(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  console.log("mmmmmmmmmmmmm", formData);
  return (
    <div className="view-edit-box-conatiner pb-5">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeEditParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">AI Service Request</h5>
          </div>
        </div>
      </div>

      {loading && (
        <Row>
          <Col className="mt-2">
            <SpinnerLoader />
          </Col>
        </Row>
      )}

      {loading === false && (
        <div className="table-view-box-content mt-4 mb-5">
          <Form onSubmit={handleContinue}>
            {formSubmitError !== null && (
              <Row>
                <Col className="mt-2">
                  <DangerAlert message={formSubmitError} />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={12} className="mt-3 mb-3">
                <div className="account-request-view-title-container">
                  <p className="account-request-view-title">Request Details</p>
                </div>
              </Col>

              <Col md={12}>
                <TextInput
                  label={t("Global ID")}
                  placeholder={t("Global ID")}
                  className="disable-div"
                  name="globalId"
                  value={formData?.aiRequestDetails?.globalId}
                  onChange={(e) => handleData(e)}
                  isInvalid={dataInputError.globalId ? true : false}
                  invalidMessage={dataInputError.globalId}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  className="disable-div"
                  label={t("Email Address")}
                  name="email"
                  value={formData?.aiRequestDetails?.email}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Email Address")}
                  isInvalid={dataInputError.email ? true : false}
                  invalidMessage={dataInputError.email}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label={t("Application Name")}
                  name="applicationName"
                  value={formData?.aiRequestDetails?.applicationName}
                  onChange={(e) => handleData(e)}
                  placeholder={t("Application Name")}
                  isInvalid={dataInputError.applicationName ? true : false}
                  invalidMessage={dataInputError.applicationName}
                />
              </Col>

              <Col md={12}>
                {" "}
                <Form.Group className="mb-3" controlId="form-subject">
                  <TextInput
                    as="textarea"
                    label={t("Use Case Description")}
                    name="useCaseDescription"
                    value={formData?.aiRequestDetails?.useCaseDescription}
                    onChange={(e) =>
                      handleDataTextArea("useCaseDescription", e.target.value)
                    }
                    placeholder={t("Use Case Description")}
                    isInvalid={dataInputError.useCaseDescription ? true : false}
                    invalidMessage={dataInputError.useCaseDescription}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <StaticDropdown
                  label={t("Cloud Service")}
                  defaultSelectMessage="Select Cloud"
                  name="cloudService"
                  value={formData?.aiRequestDetails?.cloudService}
                  onChange={(e) => handleData(e)}
                  selectValues={cloudTypeData}
                  isInvalid={dataInputError.cloudService ? true : false}
                  invalidMessage={dataInputError.cloudService}
                />
              </Col>
              <Col md={12}>
                <StaticDropdown
                  label={t("LLM Model")}
                  defaultSelectMessage="Select"
                  name="llmModel"
                  value={formData?.aiRequestDetails?.llmModel}
                  onChange={(e) => handleData(e)}
                  selectValues={
                    formData?.aiRequestDetails?.cloudService === "AWS Bedrock"
                      ? llmModelAws
                      : formData?.aiRequestDetails?.cloudService ===
                        "Azure OpenAI"
                      ? llmModelAzure
                      : formData?.aiRequestDetails?.cloudService ===
                        "Google Vertex"
                      ? llmModelGcp
                      : []
                  }
                  isInvalid={dataInputError.llmModel ? true : false}
                  invalidMessage={dataInputError.llmModel}
                />
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="form-subject">
                  <TextInput
                    as="textarea"
                    label={t("Additional Comments")}
                    name="additionalComments"
                    value={formData?.aiRequestDetails?.additionalComments}
                    onChange={(e) =>
                      handleDataTextArea("additionalComments", e.target.value)
                    }
                    placeholder={t("Additional Comments")}
                    isInvalid={dataInputError.additionalComments ? true : false}
                    invalidMessage={dataInputError.additionalComments}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <StaticDropdown
                  label={t("Have Cloud Account")}
                  defaultSelectMessage="Select Cloud"
                  name="haveCloudAccount"
                  value={formData?.aiRequestDetails?.haveCloudAccount}
                  onChange={(e) => handleData(e)}
                  selectValues={trueFalseData}
                  isInvalid={dataInputError.cloudService ? true : false}
                  invalidMessage={dataInputError.cloudService}
                />
              </Col>
              {formData?.aiRequestDetails?.haveCloudAccount === "true" && (
                <>
                  <Col md={12} className="mt-3 mb-3">
                    <div className="account-request-view-title-container">
                      <p className="account-request-view-title">
                        Account Details
                      </p>
                    </div>
                  </Col>
                  <Row>
                    <Col md={12}>
                      <TextInput
                        label={t("Cloud Account Number")}
                        name="cloudAccountNumber"
                        value={formData?.aiRequestDetails?.cloudAccountNumber}
                        onChange={(e) => handleData(e)}
                        placeholder={t("Cloud Account Number")}
                        isInvalid={
                          dataInputError.cloudAccountNumber ? true : false
                        }
                        invalidMessage={dataInputError.cloudAccountNumber}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {formData?.aiRequestDetails?.haveCloudAccount === "false" && (
                <>
                  <Col md={12} className="mt-3 mb-3">
                    <div className="account-request-view-title-container">
                      <p className="account-request-view-title">
                        Billing Details
                      </p>
                    </div>
                  </Col>

                  <Col md={12}>
                    <BillingCompanyDropdown
                      label={"*" + t("billing_op_company") + ":"}
                      defaultSelectMessage={t("select_op_company")}
                      name="billingOpco"
                      value={formData?.aiRequestDetails?.billingOpco}
                      onChange={(e) => handleData(e)}
                      selectValues={responseData}
                      isInvalid={dataInputError.billingOpco ? true : false}
                      invalidMessage={dataInputError.billingOpco}
                    />
                  </Col>

                  <Col md={12}>
                    <BillingSubCompanyDropdown
                      label={"*" + t("billing_subop_company") + ":"}
                      defaultSelectMessage={t("select_subop_company")}
                      name="billingSubOpco"
                      value={formData?.aiRequestDetails?.billingSubOpco}
                      onChange={(e) => handleData(e)}
                      selectValues={billingSubOpCompany}
                      isInvalid={dataInputError.billingSubOpco ? true : false}
                      invalidMessage={dataInputError.billingSubOpco}
                    />
                  </Col>

                  <Col md={12}>
                    <BillingDepartmentDropdown
                      label={"*" + t("billing_dep") + ":"}
                      defaultSelectMessage="Select Department"
                      name="billingDepartment"
                      value={formData?.aiRequestDetails?.billingDepartment}
                      onChange={(e) => handleData(e)}
                      selectValues={billingDepartment}
                      isInvalid={
                        dataInputError.billingDepartment ? true : false
                      }
                      invalidMessage={dataInputError.billingDepartment}
                    />
                  </Col>

                  <Col md={12}>
                    <StaticDropdown
                      label={"*" + t("bill_to") + ":"}
                      defaultSelectMessage={t("bill_to_msg")}
                      name="billTo"
                      value={formData?.aiRequestDetails?.billTo}
                      onChange={(e) => handleData(e)}
                      selectValues={staticbillto}
                      isInvalid={dataInputError.billTo ? true : false}
                      invalidMessage={dataInputError.billTo}
                    />
                  </Col>

                  {formData?.aiRequestDetails?.billTo === "GSID" ? (
                    <Col md={12}>
                      <TextInput
                        label={t("GSID")}
                        name="gsid"
                        value={formData?.aiRequestDetails?.gsid}
                        onChange={(e) => handleData(e)}
                        placeholder={t("GSID")}
                        isInvalid={dataInputError.gsid ? true : false}
                        invalidMessage={dataInputError.gsid}
                      />
                    </Col>
                  ) : null}
                  {formData?.aiRequestDetails?.billTo ===
                    "Global IS (GISC)" && (
                    <Col md={12}>
                      <TextInput
                        label="*IO:"
                        name="billingIo"
                        value={formData?.aiRequestDetails?.billingIo}
                        onChange={(e) => handleData(e)}
                        placeholder="IO"
                        isInvalid={dataInputError.billingIo ? true : false}
                        invalidMessage={dataInputError.billingIo}
                      />
                    </Col>
                  )}
                  {formData?.aiRequestDetails?.billTo === "Other Teams" && (
                    <Col md={12}>
                      <TextInput
                        label="*Cost Center:"
                        name="costCenter"
                        value={formData?.aiRequestDetails?.costCenter}
                        onChange={(e) => handleData(e)}
                        placeholder="Cost Center"
                        isInvalid={dataInputError.costCenter ? true : false}
                        invalidMessage={dataInputError.costCenter}
                      />
                    </Col>
                  )}

                  <Col md={12}>
                    <StaticDropdown
                      label={t("billing_region")}
                      defaultSelectMessage="Select Region"
                      name="billingRegion"
                      value={formData?.aiRequestDetails?.billingRegion}
                      onChange={(e) => handleData(e)}
                      selectValues={staticbillingRegion}
                      isInvalid={dataInputError.billingRegion ? true : false}
                      invalidMessage={dataInputError.billingRegion}
                    />
                  </Col>
                </>
              )}

              <Col md={12} className="mt-3">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>
                    {t("Workflow/Architecture Diagram/Supporting Docuemnts")}
                  </Form.Label>
                  {/* <Form.Control
            type='file'
            ref={ref}
            onChange={(e) => {
              uploadImage(e);
            }}
          /> */}
                  <div className="custom-upload-wrapper ">
                    <button className="custom-upload-button edit">
                      {fileLoading ? (
                        <SpinnerLoader />
                      ) : data?.supportingDocumentName ? (
                        data?.supportingDocumentName
                      ) : (
                        <>
                          <i className="fa fa-cloud-upload"></i>
                          {t("add_file")} (pdf/ jpg / png)
                        </>
                      )}
                    </button>
                    <input
                      type="file"
                      ref={ref}
                      onChange={(e) => {
                        uploadImage(e);
                      }}
                    />
                  </div>
                  {dataInputError?.supportingDocumentName && (
                    <p className="invalid-feedback d-block">
                      {dataInputError?.supportingDocumentName}
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col md={10}>
                <Form.Group className="mb-3" controlId="disclaimerCheckbox">
                  {/* todo:rd fix for link */}
                  <Form.Check
                    type="checkbox"
                    label={
                      <div style={{ fontSize: 12 }}>
                        {t(
                          "I consent and acknowledged that I have provided all the details to the best of my knwoledge by adhering to SonyAI compliance guidelness",
                          {
                            0: 1,
                          }
                        )}
                      </div>
                    }
                    defaultChecked={isDisclaimerAgreed}
                    onChange={(e) => {
                      setIsDisclaimerAgreed(!isDisclaimerAgreed);
                    }}
                  />
                </Form.Group>
              </Col>
              {/* Buttons */}
              <Col md={12} className="d-flex justify-content-end">
                <ul className="px-0 btn-ul">
                  <li>
                    <button
                      type="button"
                      className="teal-bordered-btn"
                      onClick={() => {
                        setShowCancelModal(true);
                      }}
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type="submit"
                      className={`teal-filled-btn ${
                        !isDisclaimerAgreed ? "disable-div" : ""
                      }`}
                      disabled={isDisclaimerAgreed ? false : true}
                    >
                      Submit
                    </button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {/* Cancel Modal */}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <EditCancel
              closeModalParent={closeModalCallback}
              closeEditForm={closeEditCallback}
            />
          }
        />
      )}

      {/* Submit Modal */}
      {formSubmitModal && (
        <CustomModal
          showModal={true}
          children={
            <div className="d-flex justify-content-center flex-column">
              <p>Your request was submitted successfully</p>
              <p>Check your status using this ref#</p>
              <p>u-{formSubmitRefUid}</p>
              <p>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    closeEditParent(false);
                    dispatch({
                      type: AI_REQUEST_RELOAD,
                      payload: true,
                    });
                    setFormSubmitModal(false);
                  }}
                >
                  Ok
                </button>
              </p>
            </div>
          }
        />
      )}
    </div>
  );
};

export default EditBox;
