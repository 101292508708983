import React, { useState } from "react";

const Ec2RequestConfirmation = ({ message, closeModalParent }) => {
  return (
    <div className="d-flex justify-content-center flex-column">
      <>
        {message}

        <ul className="btn-ul mb-0">
          <li>
            <button
              type="button"
              className="teal-bordered-btn"
              onClick={() => {
                closeModalParent(null);
              }}
            >
              Ok
            </button>
          </li>
        </ul>
      </>
    </div>
  );
};

export default Ec2RequestConfirmation;
