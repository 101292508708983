import { LoginCallback } from "@okta/okta-react";

// OKTA Authentication
import Home from "../pages/OktaAuth/Home";
import Landing from "../pages/OktaAuth/Landing";

// Dashboard
import DashboardIndex from "../pages/Dashboard/DashboardIndex";
import UserDashboardIndex from "pages/UserDashboard/UserDashboardIndex";

// Account Request
// Cloud Preference
import CloudPreference from "./../pages/Requests/CloudPreference/CloudPreference";
import BillingCloudPreference from "./../pages/Billings/CloudPreference/CloudPreference";

// AWS Account Request
import AWSAccountRequestViewIndex from "pages/Requests/AWS/ViewRequest/AWSAccountRequestViewIndex";
import AWSNewRequestIndex from "pages/Requests/AWS/NewRequest/AWSNewRequestIndex";

// Azure Account Request
import AzureAccountRequestViewIndex from "pages/Requests/Azure/ViewRequest/AzureAccountRequestViewIndex";
import AzureNewRequestIndex from "pages/Requests/Azure/NewRequest/AzureNewRequestIndex";

// ! CIDR
import CIDRAWSIndex from "pages/CIDR/AWS/ViewRequest/CIDRAWSIndex";
import CIDRAWSNewRequestIndex from "pages/CIDR/AWS/NewRequest/CIDRAWSNewRequestIndex";

import CIDRAzureIndex from "pages/CIDR/Azure/ViewRequest/CIDRAzureIndex";
import CIDRAzureNewRequestIndex from "pages/CIDR/Azure/NewRequest/CIDRAzureNewRequestIndex";

// ! Self Service
import GitlabIndex from "pages/Gitlab/GitlabIndex";

// ! Notification
import NotificationIndex from "pages/Notification/NotificationIndex";

// ! Account Search
import AWSAccountSearchIndex from "pages/AccountSearch/AWS/AWSAccountSearchIndex";
import AzureAccountSearchIndex from "pages/AccountSearch/Azure/AzureAccountSearchIndex";

// ! Management Cloud Eye
import ManagementCloudEyeIndex from "pages/ManagementCloudEye/ManagementCloudEyeIndex";

// ! Documents
import DocumentsIndex from "pages/Documents/DocumentsIndex";
import AddDocumentIndex from "pages/Documents/AddDocumentIndex";

// ! Okta Custom Role
import OktaCustomRoleIndex from "pages/Okta/CustomRole/ViewRequest/CustomRoleIndex";
import OktaAddRequestIndex from "pages/Okta/CustomRole/AddRequest/AddRequestIndex";

// ! Okta View Assignments
import ViewAssignmentIndex from "pages/Okta/ViewAssignment/ViewAssignmentIndex";

// ! Moriarty Trigger
import MoriartyAWSIndex from "pages/MoriartyTrigger/AWS/MoriartyAWSIndex";
import MoriartyAzureIndex from "pages/MoriartyTrigger/Azure/MoriartyAzureIndex";

// ! WorkspaceRequest
import ViewRequestIndex from "pages/WorkspaceRequest/ViewRequest/ViewRequestIndex";
import NewRequestIndex from "pages/WorkspaceRequest/NewRequest/NewRequestIndex";

// ! Request Training
import RequestTrainingViewIndex from "pages/RequestTraining/ViewRequest/RequestTrainingViewIndex";
import RequestTrainingNewIndex from "pages/RequestTraining/NewRequest/RequestTrainingNewIndex";

// ! User Cloud Eye
import UserCloudEyeIndex from "pages/UserCloudEye/UserCloudEyeIndex";
import AutomationIndex from "pages/UserCloudEye/Automation/AutomationIndex";
import CloudEyeSecurityIndex from "pages/UserCloudEye/Security/CloudEyeSecurityIndex";

// ! Cloud Eye Metrics
import EC2MetricsIndex from "pages/UserCloudEye/Metrics/EC2/EC2MetricsIndex";
import EFSMetricsIndex from "pages/UserCloudEye/Metrics/EFS/EFSMetricsIndex";
import LBMetricsIndex from "pages/UserCloudEye/Metrics/LB/LBMetricsIndex";
import RDSMetricsIndex from "pages/UserCloudEye/Metrics/RDS/RDSMetricsIndex";
import LAMBDAMetricsIndex from "pages/UserCloudEye/Metrics/LAMBDA/LAMBDAMetricsIndex";
import ApiGatewayMetricsIndex from "pages/UserCloudEye/Metrics/APIGATEWAY/ApiGatewayMetricsIndex";
import DYNAMODBMetricsIndex from "pages/UserCloudEye/Metrics/DYNAMODB/DYNAMODBMetricsIndex";
import CLOUDFRONTMetricsIndex from "pages/UserCloudEye/Metrics/CLOUDFRONT/CLOUDFRONTMetricsIndex";

// ! Security
import SecurityIndex from "pages/Security/SecurityIndex";

// ! Cloud Eye User Logs
import CloudeyeLogsIndex from "pages/UserCloudEye/Logs/CloudeyeLogsIndex";

// ! Network Hub
import NetworkHubViewIndex from "pages/NetworkHub/ViewRequest/NetworkHubViewIndex";
import NetworkHubAddIndex from "pages/NetworkHub/AddRequest/NetworkHubAddIndex";

// ! Managed AD Requests
import ManagedADRequestsViewIndex from "pages/ManagedADRequests/ViewRequest/ManagedADRequestsViewIndex";
import ManagedADRequestAddIndex from "pages/ManagedADRequests/AddRequest/ManagedADRequestAddIndex";

// ! Templates
import ViewTemplateIndex from "pages/Templates/ViewTemplate/ViewTemplateIndex";
import AddTemplateIndex from "pages/Templates/AddTemplate/AddTemplateIndex";

// ! Cloud Eye Management User View
import CEMUVUserCloudEyeIndex from "pages/CloudEyeManagementUserView/CEMUVUserCloudEyeIndex";
import CEMUVCloudeyeLogsIndex from "pages/CloudEyeManagementUserView/Logs/CEMUVCloudeyeLogsIndex";
import CEMUVCAutomationIndex from "pages/CloudEyeManagementUserView/Automation/CEMUVAutomationIndex";
import CEMUVCCloudEyeSecurityIndex from "pages/CloudEyeManagementUserView/Security/CEMUVCCloudEyeSecurityIndex";

import CEMUVEC2MetricsIndex from "pages/CloudEyeManagementUserView/Metrics/EC2/CEMUVEC2MetricsIndex";
import CEMUVEFSMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/EFS/CEMUVEFSMetricsIndex";
import CEMUVLBMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/LB/CEMUVLBMetricsIndex";
import CEMUVRDSMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/RDS/CEMUVRDSMetricsIndex";
import CEMUVLAMBDAMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/LAMBDA/CEMUVLAMBDAMetricsIndex";
import CEMUVApiGatewayMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/APIGATEWAY/CEMUVApiGatewayMetricsIndex";
import CEMUVDYNAMODBMetricsIndex from "pages/CloudEyeManagementUserView/Metrics/DYNAMODB/CEMUVDYNAMODBMetricsIndex";
import HelpAndSupportIndex from "pages/HelpAndSupport/HelpAndSupportIndex";
import OnDemandScanIndex from "pages/onDemandScan";
import CreateCloudEyeRequests from "pages/UserCloudEye/Requests/index";
import ViewRequestsInCloudEye from "pages/UserCloudEye/Requests/view";
import ResourceQuery from "pages/ResrouceQuery";
import AccountUpdateCloudPreference from "pages/AccountUpdate/AccountUpdateCloudPreference/AccountUpdateCloudPreference";
import AzureAccountUpdateRequestViewIndex from "pages/AccountUpdate/Azure/ViewRequest/AzureAccountUpdateRequestViewIndex";
import AzureListIndex from "pages/AccountUpdate/Azure/NewRequest/AzureListIndex";
import AWSAccountUpdateRequestViewIndex from "pages/AccountUpdate/AWS/ViewRequest/AWSAccountUpdateRequestViewIndex";
import AWSListIndex from "pages/AccountUpdate/AWS/NewRequest/AWSListIndex";
import ResourceQueryCloudPreference from "pages/ResrouceQuery/ResourceQueryCloudPreference/ResourceQueryCloudPreference";
import AWSBillings from "pages/Billings/AWS/AWSBillings";
import AzureBillings from "pages/Billings/Azure/AzureBillings";
//vpc endpoint
import VpcViewIndex from "pages/VPC/ViewRequest/VpcViewIndex";
import VpcAddIndex from "pages/VPC/AddRequest/VpcAddIndex";
import VpcDisassociationForm from "pages/VPC/VpcDisassociationForm";

// trend micro onboarding

import TrendMicroCloudPreference from "./../pages/TrendMicroOnboarding/Requests/CloudPreference/CloudPreference";
//import TrendMicroAwsViewIndex from './../pages/TrendMicroOnboarding/Requests/AWS/ViewRequest/AWSAccountRequestViewIndex'
import TrendMicroAzureViewIndex from "./../pages/TrendMicroOnboarding/Requests/Azure/ViewRequest/AzureAccountRequestViewIndex";
//import TrendMicroAwsNewRequestIndex from './../pages/TrendMicroOnboarding/Requests/AWS/NewRequest/AWSNewRequestIndex'
import TrendMicroAzureNewRequestIndex from "./../pages/TrendMicroOnboarding/Requests/Azure/NewRequest/AzureNewRequestIndex";
//import AzureLoginForm from "pages/AzureLoginOnboarding/AzureLoginForm";
// ! Custom Role
// import CustomRoleIndex from "pages/CustomRole/ViewRequest/CustomRoleIndex";
// import AddRequestIndex from "pages/CustomRole/AddRequest/AddRequestIndex";

// ! Page Not Found
import NotFound from "pages/NotFound";
import GCPAccountRequestViewIndex from "pages/Requests/GCP/ViewRequest/GCPAccountRequestViewIndex";
import GCPNewRequestIndex from "pages/Requests/GCP/NewRequest/GCPNewRequestIndex";
import AWSHostRequestIndex from "pages/Requests/AWSHostPachManagement/NewRequest/AWSHostRequestIndex";
import AWSHostRequestViewIndex from "pages/Requests/AWSHostPachManagement/ViewRequest/AWSHostRequestViewIndex";

//tools exception
import ExceptionRequestIndex from "pages/Security/AWS/ToolsExceptionHandling/AddRequest/ExceptionRequestIndex";
import ExceptionViewIndex from "pages/Security/AWS/ToolsExceptionHandling/ViewRequest/ExceptionViewIndex";
import ToolsExceptionHome from "pages/Security/CloudPreference/CloudPreference";
import AzureExceptionRequest from "pages/Security/Azure/ToolsExceptionHandling/AddRequest/AzureExceptionRequest";
import AzureExceptionView from "pages/Security/Azure/ToolsExceptionHandling/ViewRequest/AzureExceptionView";

import BillingDetail from "pages/Requests/BillingDetail/BillingDetail";
import BillingDetailViewIndex from "pages/Requests/BillingDetail/BillingDetailViewIndex";

//Aws credits button page
import AwsCreditsViewIndex from "pages/Billings/AWS/AWSCredits/AwsCreditsViewIndex";
import GSIDIOViewIndex from "pages/Billings/AWS/GSIDIO/GSIDIOViewIndex";
import ModificationViewIndex from "pages/Billings/AWS/Modification/ModificationViewIndex";
import AWSBackupViewIndex from "pages/Requests/AWSBackUp/ViewRequest/AWSAccountRequestViewIndex";
import NewBackupRequestIndex from "pages/Requests/AWSBackUp/NewRequest/NewBackupRequestIndex";
import PAMCostViewIndex from "pages/Billings/AWS/PAMCosts/PAMCostViewIndex";
// import CostDataIndex from "pages/Billings/CostData/CostDataIndex";
// import GCSChargesViewIndex from "pages/Billings/CostData/GCSCharges/GCSChargesViewIndex";
// import TrendMicroChargesViewIndex from "pages/Billings/CostData/TrendMicroCharges/TrendMicroChargesViewIndex";
// import HostingChargesViewIndex from "pages/Billings/CostData/HostingChargesWithoutSp/HostingChargesViewIndex";
// import HostingChargesSpViewIndex from "pages/Billings/CostData/HostingChargesWithSp/HostingChargesSpViewIndex";
import AINewRequestIndex from "pages/Requests/AIRequest/NewRequest/AINewRequestIndex";
import AIViewRequestIndex from "pages/Requests/AIRequest/ViewRequest/AIViewRequestIndex";
import NotificationCloudPreference from "pages/Notification/NotificationCloudPreference";
// import AmazonQNewRequestIndex from "pages/Requests/AmazonQRequest/NewRequest/AmazonQNewRequestIndex";
// import AmazonQViewRequestIndex from "pages/Requests/AmazonQRequest/ViewRequest/AmazonQViewRequestIndex";

const publicRoutes = [
  { path: "/", component: Home },
  { path: "/login/callback", component: LoginCallback },
];

const validateRoutes = [{ path: "/landing", component: Landing }];

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardIndex },
  { path: "/user-dashboard", component: UserDashboardIndex },

  { path: "/cloud-preference", component: CloudPreference },
  { path: "/aws-account-requests", component: AWSAccountRequestViewIndex },
  { path: "/aws-new-account-request", component: AWSNewRequestIndex },
  { path: "/azure-account-requests", component: AzureAccountRequestViewIndex },
  { path: "/azure-new-account-request", component: AzureNewRequestIndex },
  { path: "/gcp-new-account-request", component: GCPNewRequestIndex },
  { path: "/gcp-account-requests", component: GCPAccountRequestViewIndex },
  { path: "/aws-host-patch-request", component: AWSHostRequestViewIndex },
  { path: "/aws-host-patch-new-request", component: AWSHostRequestIndex },

  { path: "/account-update", component: AccountUpdateCloudPreference },
  {
    path: "/aws-account-update-requests",
    component: AWSAccountUpdateRequestViewIndex,
  },
  {
    path: "/azure-account-update-requests",
    component: AzureAccountUpdateRequestViewIndex,
  },
  { path: "/azure-account-list", component: AzureListIndex },
  { path: "/aws-account-list", component: AWSListIndex },

  { path: "/aws-cidr-view-requests", component: CIDRAWSIndex },
  { path: "/aws-cidr-new-request", component: CIDRAWSNewRequestIndex },

  { path: "/azure-cidr-view-requests", component: CIDRAzureIndex },
  { path: "/azure-cidr-new-request", component: CIDRAzureNewRequestIndex },

  { path: "/gitlab", component: GitlabIndex },

  { path: "/notification", component: NotificationIndex },

  { path: "/aws-account-search", component: AWSAccountSearchIndex },
  { path: "/azure-account-search", component: AzureAccountSearchIndex },

  { path: "/documents", component: DocumentsIndex },
  { path: "/add-document", component: AddDocumentIndex },

  { path: "/okta-custom-role-view", component: OktaCustomRoleIndex },
  { path: "/okta-custom-role-add", component: OktaAddRequestIndex },
  { path: "/okta-view-assignment", component: ViewAssignmentIndex },

  { path: "/aws-moriarty", component: MoriartyAWSIndex },
  { path: "/azure-moriarty", component: MoriartyAzureIndex },

  { path: "/aws-workspaces-view", component: ViewRequestIndex },
  { path: "/aws-workspaces-add", component: NewRequestIndex },

  { path: "/request-training-view", component: RequestTrainingViewIndex },
  { path: "/request-training-add", component: RequestTrainingNewIndex },

  { path: "/security", component: SecurityIndex },

  { path: "/network-hub-view", component: NetworkHubViewIndex },
  { path: "/network-hub-add", component: NetworkHubAddIndex },

  { path: "/managedad-requests-view", component: ManagedADRequestsViewIndex },
  { path: "/managedad-requests-add", component: ManagedADRequestAddIndex },

  { path: "/templates-view", component: ViewTemplateIndex },
  { path: "/templates-add", component: AddTemplateIndex },

  { path: "/help-support", component: HelpAndSupportIndex },
  { path: "/on-demand-scan", component: OnDemandScanIndex },
  { path: "/create-cloud-eye-request", component: CreateCloudEyeRequests },
  { path: "/view-cloud-eye-request", component: ViewRequestsInCloudEye },
  // { path: "/resource-query", component: ResourceQueryCloudPreference },
  { path: "/aws-resource-query", component: ResourceQuery },
  { path: "/azure-resource-query", component: ResourceQuery },
  { path: "/billings", component: BillingCloudPreference },
  { path: "/aws-billings", component: AWSBillings },
  { path: "/azure-billings", component: AzureBillings },

  // vpc endpoint
  { path: "/vpc-endpoint-view", component: VpcViewIndex },
  { path: "/vpc-endpoint-add", component: VpcAddIndex },
  { path: "/vpc-endpoint-disassociation", component: VpcDisassociationForm },

  // Trend Micro Onboarding
  { path: "/trend-micro-onboarding", component: TrendMicroCloudPreference },
  // { path: "/trend-micro-aws-requests", component: TrendMicroAwsViewIndex },
  { path: "/trend-micro-azure-requests", component: TrendMicroAzureViewIndex },
  // { path: "/trend-micro-aws-account-request", component: TrendMicroAwsNewRequestIndex },
  {
    path: "/trend-micro-azure-account-request",
    component: TrendMicroAzureNewRequestIndex,
  },

  { path: "/notification-preference", component: NotificationCloudPreference },

  // Azure Onboarding
  // { path: "/azure-login/new-account", component: AzureLoginForm },

  // Tool Exception Handling
  // { path: "/tool-exception-dashboard", component: ExceptionViewIndex },

  // Tool Exception Handling
  { path: "/tool-exception-aws-view", component: ExceptionViewIndex },
  { path: "/tool-exception-aws-add", component: ExceptionRequestIndex },

  // azure
  { path: "/tool-exception-azure-view", component: AzureExceptionView },
  { path: "/tool-exception-azure-add", component: AzureExceptionRequest },
  { path: "/tool-exception-index", component: ToolsExceptionHome },
  //Billing details
  { path: "/billing-edit", component: BillingDetailViewIndex },

  //Aws credits button page
  { path: "/aws-credits", component: AwsCreditsViewIndex },

  //GSID IO page
  { path: "/aws-gsidio", component: GSIDIOViewIndex },

  //modification button page
  { path: "/aws-modification", component: ModificationViewIndex },

  //cost data CostDataIndex
  // { path: "/cost-data", component: CostDataIndex },
  // { path: "/gcs-charges", component: GCSChargesViewIndex },
  // { path: "/tm-charges", component: TrendMicroChargesViewIndex },
  // { path: "/hosting-charges-sp", component: HostingChargesSpViewIndex },
  // { path: "/hosting-charges", component: HostingChargesViewIndex },

  // Aws Backup Module
  { path: "/aws-backup-new-request", component: NewBackupRequestIndex },
  { path: "/aws-backup-requests", component: AWSBackupViewIndex },

  //Billing Module - pam costs

  { path: "/aws-pamcosts", component: PAMCostViewIndex },

  // Request Module - AI Request
  { path: "/ai-request-new", component: AINewRequestIndex },
  { path: "/ai-request-view", component: AIViewRequestIndex },

  //Request Module - Amazon Q Request
  // { path: "/q-request-new", component: AmazonQNewRequestIndex },
  // { path: "/q-request-view", component: AmazonQViewRequestIndex },
];

const authProtectedFullLayoutRoutes = [
  // Cloud Eye Management Dashboard
  { path: "/cloudeye-management", component: ManagementCloudEyeIndex },

  // Cloud Eye User Dashboard
  { path: "/cloudeye-user", component: UserCloudEyeIndex },
  { path: "/cloudeye-user-automation", component: AutomationIndex },
  { path: "/cloudeye-user-security", component: CloudEyeSecurityIndex },
  { path: "/cloudeye-user-logs", component: CloudeyeLogsIndex },
  // Metrics
  {
    path: "/ec2-metrics/:application/:instanceid/:displayname/:platform",
    component: EC2MetricsIndex,
  },
  {
    path: "/efs-metrics/:application/:instanceid/:displayname",
    component: EFSMetricsIndex,
  },
  {
    path: "/lb-metrics/:application/:instanceid/:type",
    component: LBMetricsIndex,
  },
  {
    path: "/rds-metrics/:application/:instanceid/:displayname",
    component: RDSMetricsIndex,
  },
  {
    path: "/lambda-metrics/:application/:instanceid/:displayname",
    component: LAMBDAMetricsIndex,
  },
  {
    path: "/apigateway-metrics/:application/:instanceid/:displayname",
    component: ApiGatewayMetricsIndex,
  },
  {
    path: "/dynamodb-metrics/:application/:instanceid/:displayname",
    component: DYNAMODBMetricsIndex,
  },
  {
    path: "/cloudfront-metrics/:application/:instanceid/:displayname",
    component: CLOUDFRONTMetricsIndex,
  },
  // ! Cloud Eye Management User View
  {
    path: "/cloudeye-management-user-view-dashboard/:application",
    component: CEMUVUserCloudEyeIndex,
  },
  {
    path: "/cloudeye-management-user-view-logs/:application",
    component: CEMUVCloudeyeLogsIndex,
  },
  {
    path: "/cloudeye-management-user-view-automation/:application",
    component: CEMUVCAutomationIndex,
  },
  {
    path: "/cloudeye-management-user-view-security/:application",
    component: CEMUVCCloudEyeSecurityIndex,
  },

  // ! Cloud Eye Management User View - Metrics

  {
    path:
      "/cloudeye-management-user-view/ec2-metrics/:application/:instanceid/:displayname",
    component: CEMUVEC2MetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/efs-metrics/:application/:instanceid/:displayname",
    component: CEMUVEFSMetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/lb-metrics/:application/:instanceid/:type",
    component: CEMUVLBMetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/rds-metrics/:application/:instanceid/:displayname",
    component: CEMUVRDSMetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/lambda-metrics/:application/:instanceid/:displayname",
    component: CEMUVLAMBDAMetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/apigateway-metrics/:application/:instanceid/:displayname",
    component: CEMUVApiGatewayMetricsIndex,
  },
  {
    path:
      "/cloudeye-management-user-view/dynamodb-metrics/:application/:instanceid/:displayname",
    component: CEMUVDYNAMODBMetricsIndex,
  },
];

export {
  authProtectedRoutes,
  publicRoutes,
  validateRoutes,
  authProtectedFullLayoutRoutes,
};
