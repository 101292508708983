import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// Icon
import snoozeIcon from "icons/snooze-icon.svg";

// Helper

// Internal
import MenuTabs from "./MenuTabs";
import QuickOverView from "./QuickOverView";
import ApplicationHealth from "./ApplicationHealth";
import WebserviceDatatable from "./WebserviceDatatable";
import ServiceDatatable from "./ServiceDatatable";
import ApplicationDatatable from "./ApplicationDatatable";
import ResourceHealthDatatable from "./ResourceHealthDatatable";

import SnoozeAlarm from "./SnoozeAlarm";

// Cloud & Infrastructure Datatable
import EC2Datatable from "./EC2Datatable";
import EFSDatatable from "./EFSDatatable";
import LBDatatable from "./LBDatatable";
import RDSDatatable from "./RDSDatatable";
import LambdaDatatable from "./LambdaDatatable";
import ApiGatewayDatatable from "./ApiGatewayDatatable";
import DynamoDBDatatable from "./DynamoDBDatatable";
import CloudFrontDatatable from "./CloudFrontDatatable";
import FsxDatatable from "./FsxDatatable";

// Icons
import amazonec2icon from "icons/aws-ec2-icon.svg";
import amazonefsicon from "icons/amazon-efs-icon.svg";
import amazonlbicon from "icons/aws-elastic-load-balancing-icon.svg";
import amazonrdsicon from "icons/aws-rds-icon.svg";
import amazonlambda from "icons/aws-lambda-icon.svg";
import amazonapigateway from "icons/aws-api-gateway-icon.svg";
import amazondynamodb from "icons/aws-dynamodb-icon.svg";
import amazoncloudfront from "icons/amazon-cloudfront-icon.svg";

import {
  CLOUD_EYE_USER_TABLE,
  CLOUD_EYE_APPLICATION_NAME,
} from "redux/cloudeye-user/cloueyeUserConstant";
import { useQueryClient } from "react-query";
import Api from "api/api";
import {
  useResourceHealthApi,
  useUserAppMetricsApi,
  useUserServiceApi,
  useWebServiceApi,
} from "./dataloader";
import ReactSelect from "react-select";

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    padding: 2,
    margin: 2,
  }),
  singleValue: (provided, state) => {
    return { ...provided, fontSize: 14 };
  },
};

const CloudEyeMonitor = () => {
  const isMounted = useRef(false);
  const queryClient = useQueryClient();
  const applist = queryClient.getQueryData([Api.cloudEyeAppList]);

  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeUserTable, cloudEyeApplicationName } = cloudEye;

  // Application Bar State
  const [toggleApplicationBar, setToggleApplicationBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setToggleApplicationBar(true);
    }
  };

  const [selectedApplicationName, setSelectedApplicationName] = useState(
    cloudEyeApplicationName || ""
  );

  // Webservice

  const {
    data: webServiceApiData,
    isFetching: webserviceApiLoading,
    error: webServiceApiError,
  } = useWebServiceApi(selectedApplicationName?.trim());

  // Resource Health

  const {
    data: resourceHealthApiData,
    isFetching: resourceHealthApiLoading,
    error: resourceHealthApiError,
  } = useResourceHealthApi(selectedApplicationName?.trim());

  // Service
  const {
    data: serviceApiData,
    isFetching: serviceApiLoading,
    error: serviceApiError,
  } = useUserServiceApi(selectedApplicationName?.trim());

  // Application

  const {
    data: applicationApiData,
    isFetching: applicationApiLoading,
    error: applicationApiError,
  } = useUserAppMetricsApi(selectedApplicationName?.trim());

  // Cloud & Infrastructure
  const [cloudInfrastructureValue, setCloudInfrastructureValue] = useState("");
  const [cloudInfrastructureNotify, setCloudInfrastructureNotify] = useState(
    []
  );

  // Snooze Alarm Part
  const [showSnoozeAlarm, setShowSnoozeAlarm] = useState(false);

  // ! Close Snooze Callback
  const closeSnoozeCallback = useCallback((value) => {
    setShowSnoozeAlarm(value);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (cloudEyeUserTable === "Webservice Health") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Webservice Health");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Resource Health") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Resource Health");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Service") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Service");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Application") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Application");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }
    }

    return () => {};
  }, [cloudEyeUserTable]);

  useEffect(() => {
    if (isMounted.current) {
      if (selectedApplicationName !== "") {
        dispatch({
          type: CLOUD_EYE_USER_TABLE,
          payload: "",
        });
      }
    }
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleApplicationBar]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const { t } = useTranslation();

  return (
    <Row>
      {toggleApplicationBar ? (
        <Col md={2} className="px-0 custom-application-bar">
          <div
            className="custom-arrow-icon"
            onClick={() => setToggleApplicationBar(false)}
          >
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="application-name-container">
            <span className="subtitle-text px-1">
              {t("application_details")}
            </span>
            <Form.Group
              className="mt-3"
              style={{ fontSize: "12px !important" }}
            >
              <ReactSelect
                styles={customStyles}
                isLoading={!applist?.length}
                value={{
                  label: selectedApplicationName,
                  value: selectedApplicationName,
                }}
                options={applist
                  .map((item) => item.replace(":ReadOnly", ""))
                  .map((data, index) => ({
                    label: data.trim(),
                    value: data.trim(),
                  }))}
                onChange={(obj) => {
                  setSelectedApplicationName(obj.value);
                  dispatch({
                    type: CLOUD_EYE_APPLICATION_NAME,
                    payload: `${obj.value}`,
                  });
                }}
              />
            </Form.Group>
          </div>
        </Col>
      ) : (
        <Col md={1} className="px-0 custom-application-bar">
          <div
            className="custom-arrow-icon"
            onClick={() => setToggleApplicationBar(true)}
          >
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="application-name-container">
            <h5 className="application-bar-text-rotate">
              {t("application_details")}
            </h5>
          </div>
        </Col>
      )}

      <Col md={toggleApplicationBar ? 10 : 11} className="px-3">
        <MenuTabs
          applicationName={selectedApplicationName}
          accountName={resourceHealthApiData}
          accountNameLoading={resourceHealthApiLoading}
        />

        {selectedApplicationName === "" && (
          <div className="d-flex justify-content-center align-items-center ">
            {applist.length
              ? t("please_select_the_application")
              : "No Application Assigned"}
          </div>
        )}
        {selectedApplicationName !== "" && (
          <div className="mt-4">
            <Row>
              <Col md={6} className="mb-3">
                <div className="application-container-title mb-3">
                  {t("quick_overview")}
                </div>
                <QuickOverView
                  webserviceLoading={webserviceApiLoading}
                  webserviceData={webServiceApiData}
                  webServiceError={webServiceApiError}
                  resourceHealthLoading={resourceHealthApiLoading}
                  resourceHealthData={resourceHealthApiData}
                  resourceHealthError={resourceHealthApiError}
                />
              </Col>

              <Col md={6} className="mb-3">
                <div className="application-container-title mb-3">
                  {t("application_health")}
                </div>
                <ApplicationHealth
                  serviceLoading={serviceApiLoading}
                  serviceData={serviceApiData}
                  serviceError={serviceApiError}
                  applicationLoading={applicationApiLoading}
                  applicationData={applicationApiData}
                  applicationError={applicationApiError}
                />
              </Col>
            </Row>
          </div>
        )}
        {selectedApplicationName !== "" &&
          resourceHealthApiLoading === false &&
          resourceHealthApiData.data.body.length >= 0 && (
            <div className="mt-4 mb-3">
              <Row>
                <Col md={8} className="mb-3">
                  <div className="application-container-title mb-3">
                    {t("cloud_and_infra")}
                  </div>
                </Col>
                {applist.includes(`${selectedApplicationName}:ReadOnly`) ===
                  false && (
                  <Col md={4} className="mb-3">
                    <button
                      type="button"
                      className="teal-bordered-btn w-100"
                      onClick={() => {
                        setShowSnoozeAlarm(true);
                      }}
                    >
                      <img src={snoozeIcon} alt="Snooze" />{" "}
                      {t("snooze_all_resource_alerts")}
                    </button>
                  </Col>
                )}
              </Row>
              <Row>
                {resourceHealthApiData.data.body.map((data, index) => (
                  <Col
                    md={3}
                    key={index}
                    className="mb-3 custom-pointer custom-realtive"
                    onClick={() => {
                      setCloudInfrastructureValue(data.resource);
                      dispatch({
                        type: CLOUD_EYE_USER_TABLE,
                        payload: "Cloud & Infrastructure",
                      });
                      setTimeout(() => {
                        var elmntToView = document.getElementById(
                          data.resource
                        );
                        elmntToView.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "start",
                        });
                      }, 1000);
                    }}
                  >
                    {resourceHealthApiData?.data?.issueres?.includes(
                      data.resource
                    ) && (
                      <div className="custom-notify">
                        <i className="fa fa-exclamation "></i>
                      </div>
                    )}
                    {data.count >= 0 ? (
                      <div className="custom-card cloud-infrastructure-container ">
                        <div
                          className="regular-text-bold"
                          style={{ marginLeft: "14px" }}
                        >
                          Amazon{" "}
                          <span className="text-uppercase">
                            {data.resource}
                          </span>
                        </div>
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                          {data.resource === "ec2" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonec2icon}
                                alt="EC2"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "efs" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonefsicon}
                                alt="EFS"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "lb" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonlbicon}
                                alt="LB"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "rds" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonrdsicon}
                                alt="RDS"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "lambda" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonlambda}
                                alt="Lambda"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "apigateway" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazonapigateway}
                                alt="API Gateway"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "dynamodb" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazondynamodb}
                                alt="Dynamo DB"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          {data.resource === "cloudfront" && (
                            <div className="cloud-infrastructure-img-container">
                              <img
                                src={amazoncloudfront}
                                alt="CloudFront"
                                className="cloud-infrastructure-img"
                              />
                            </div>
                          )}
                          <span className="px-4 cloud-infrastructure-count-text">
                            {data.count}
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </Col>
                ))}
              </Row>
            </div>
          )}

        <div id="Webservice Health">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Webservice Health" && (
              <Col md={12}>
                <WebserviceDatatable
                  webserviceData={webServiceApiData}
                  selectedApplicationName={selectedApplicationName}
                  apps={applist}
                />
              </Col>
            )}
        </div>

        <div id="Resource Health">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Resource Health" && (
              <ResourceHealthDatatable
                resourceHealthData={resourceHealthApiData}
              />
            )}
        </div>

        <div id="Service">
          {selectedApplicationName !== "" && cloudEyeUserTable === "Service" && (
            <Col md={12}>
              <ServiceDatatable serviceData={serviceApiData} />
            </Col>
          )}
        </div>

        <div id="Application">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Application" && (
              <Col md={12}>
                <ApplicationDatatable applicationData={applicationApiData} />
              </Col>
            )}
        </div>

        <div id="ec2">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "ec2" && (
              <EC2Datatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="efs">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "efs" && (
              <EFSDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="lb">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "lb" && (
              <LBDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="rds">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "rds" && (
              <RDSDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="lambda">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "lambda" && (
              <LambdaDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>
        <div id="cloudfront">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "cloudfront" && (
              <CloudFrontDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="apigateway">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "apigateway" && (
              <ApiGatewayDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        <div id="dynamodb">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "dynamodb" && (
              <DynamoDBDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>
        <div id="Fsx">
          {selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "Fsx" && (
              <FsxDatatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
              />
            )}
        </div>

        {/* Snooze Alarm */}
        {showSnoozeAlarm && (
          <SnoozeAlarm
            application={selectedApplicationName}
            closeSnoozeParent={closeSnoozeCallback}
          />
        )}
      </Col>
    </Row>
  );
};

export default CloudEyeMonitor;
