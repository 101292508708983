import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// Icon
import snoozeIcon from "icons/snooze-icon.svg";

// Helper

// Internal
import MenuTabs from "../MenuTabs";

import SnoozeAlarm from "../SnoozeAlarm";

// Cloud & Infrastructure Datatable
import EC2Datatable from "../EC2Datatable";

// Icons
import amazonec2icon from "icons/aws-ec2-icon.svg";
import amazonefsicon from "icons/amazon-efs-icon.svg";
import amazonlbicon from "icons/aws-elastic-load-balancing-icon.svg";
import amazonrdsicon from "icons/aws-rds-icon.svg";
import amazonlambda from "icons/aws-lambda-icon.svg";
import amazonapigateway from "icons/aws-api-gateway-icon.svg";
import amazondynamodb from "icons/aws-dynamodb-icon.svg";
import amazoncloudfront from "icons/amazon-cloudfront-icon.svg";

import {
  CLOUD_EYE_USER_TABLE,
  CLOUD_EYE_APPLICATION_NAME,
} from "redux/cloudeye-user/cloueyeUserConstant";
import { useQueryClient } from "react-query";
import Api from "api/api";
import {
  useResourceHealthApi,
  useUserAppMetricsApi,
  useUserServiceApi,
  useWebServiceApi,
} from "../dataloader";
import ReactSelect from "react-select";
import StaticDropdown from "components/Forms/StaticDropdown";
import { staticCategoryOption } from "static-contents/cloud-eye-data";
import Loader from "components/Common/Loader";
import AutomationEC2Datatable from "./AutomationEC2Datatable";

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    padding: 2,
    margin: 2,
  }),
  singleValue: (provided, state) => {
    return { ...provided, fontSize: 14 };
  },
};

const AutomationCloudEyeMonitor = () => {
  const isMounted = useRef(false);
  const queryClient = useQueryClient();
  const applist = queryClient.getQueryData([Api.cloudEyeAppList]);

  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const cloudEye = useSelector((state) => state.cloudEye);
  const { cloudEyeUserTable, cloudEyeApplicationName } = cloudEye;

  // Application Bar State
  const [toggleApplicationBar, setToggleApplicationBar] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setToggleApplicationBar(true);
    }
  };

  const [selectedApplicationName, setSelectedApplicationName] = useState("");

  useEffect(() => {
    if (selectedApplicationName === "") {
      setSelectedApplicationName(cloudEyeApplicationName);
      setAutomationCategory("");
    }
  }, [selectedApplicationName]);

  useEffect(() => {
    setSelectedApplicationName(cloudEyeApplicationName);
  }, [cloudEyeApplicationName]);
  // Webservice

  const {
    data: webServiceApiData,
    isFetching: webserviceApiLoading,
    error: webServiceApiError,
  } = useWebServiceApi(selectedApplicationName?.trim());

  // Resource Health

  const {
    data: resourceHealthApiData,
    isFetching: resourceHealthApiLoading,
    error: resourceHealthApiError,
  } = useResourceHealthApi(selectedApplicationName?.trim());

  // Service
  const {
    data: serviceApiData,
    isFetching: serviceApiLoading,
    error: serviceApiError,
  } = useUserServiceApi(selectedApplicationName?.trim());

  // Application

  const {
    data: applicationApiData,
    isFetching: applicationApiLoading,
    error: applicationApiError,
  } = useUserAppMetricsApi(selectedApplicationName?.trim());

  // Cloud & Infrastructure
  const [cloudInfrastructureValue, setCloudInfrastructureValue] = useState("");
  const [cloudInfrastructureNotify, setCloudInfrastructureNotify] = useState(
    []
  );

  // Snooze Alarm Part
  const [showSnoozeAlarm, setShowSnoozeAlarm] = useState(false);

  //static category state
  const [automationCategory, setAutomationCategory] = useState("");

  // ! Close Snooze Callback
  const closeSnoozeCallback = useCallback((value) => {
    setShowSnoozeAlarm(value);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (cloudEyeUserTable === "Webservice Health") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Webservice Health");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Resource Health") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Resource Health");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Service") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Service");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }

      if (cloudEyeUserTable === "Application") {
        setTimeout(() => {
          var elmntToView = document.getElementById("Application");
          elmntToView.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }, 1000);
      }
    }

    return () => {};
  }, [cloudEyeUserTable]);

  useEffect(() => {
    if (isMounted.current) {
      if (selectedApplicationName !== "") {
        dispatch({
          type: CLOUD_EYE_USER_TABLE,
          payload: "",
        });
      }
    }
  }, [selectedApplicationName, dispatch]);

  useEffect(() => {
    if (isMounted.current) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleApplicationBar]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const { t } = useTranslation();

  const updateSelectedAppName = (value) => {
    setSelectedApplicationName(value);
  };
  return resourceHealthApiLoading === true ? (
    <Loader />
  ) : (
    <Row>
      <Col md={12} className="px-3">
        {selectedApplicationName === "" && (
          <div className="d-flex justify-content-center align-items-center ">
            {applist?.length
              ? t("please_select_the_application")
              : "No Application Assigned"}
          </div>
        )}

        {selectedApplicationName !== "" &&
          resourceHealthApiLoading === false &&
          resourceHealthApiData?.data?.body?.length >= 0 && (
            <div className="mt-4 mb-3">
              <Row>
                <Col md={3}>
                  <Card className="select-category-container">
                    <StaticDropdown
                      label={"Select Category"}
                      defaultSelectMessage="Select"
                      name="automationCategory"
                      value={automationCategory}
                      onChange={(e) => setAutomationCategory(e.target.value)}
                      selectValues={staticCategoryOption}
                    />
                  </Card>
                </Col>
                {automationCategory === "Start/ Stop/ Restart" &&
                  resourceHealthApiData?.data?.body.map(
                    (data, index) =>
                      data.resource === "ec2" && (
                        <Col
                          md={3}
                          key={index}
                          className="mb-3 custom-pointer custom-realtive"
                          onClick={() => {
                            setCloudInfrastructureValue(data.resource);
                            dispatch({
                              type: CLOUD_EYE_USER_TABLE,
                              payload: "Cloud & Infrastructure",
                            });
                            setTimeout(() => {
                              var elmntToView = document.getElementById(
                                data?.resource
                              );
                              elmntToView.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }, 1000);
                          }}
                        >
                          {resourceHealthApiData?.data?.issueres?.includes(
                            data?.resource
                          ) && (
                            <div className="custom-notify">
                              <i className="fa fa-exclamation "></i>
                            </div>
                          )}
                          {data?.count >= 0
                            ? data?.resource === "ec2" && (
                                <div className="custom-card cloud-infrastructure-container ">
                                  <div
                                    className="regular-text-bold"
                                    style={{ marginLeft: "14px" }}
                                  >
                                    Amazon{" "}
                                    <span className="text-uppercase">
                                      {data?.resource}
                                    </span>
                                  </div>
                                  <div className="mt-3 d-flex justify-content-center align-items-center">
                                    <div className="cloud-infrastructure-img-container">
                                      <img
                                        src={amazonec2icon}
                                        alt="EC2"
                                        className="cloud-infrastructure-img"
                                      />
                                    </div>

                                    <span className="px-4 cloud-infrastructure-count-text">
                                      {data?.count}
                                    </span>
                                  </div>
                                </div>
                              )
                            : null}
                        </Col>
                      )
                  )}
              </Row>
            </div>
          )}
      </Col>
      <Col md={12}>
        <div id="ec2">
          {automationCategory === "Start/ Stop/ Restart" &&
            selectedApplicationName !== "" &&
            cloudEyeUserTable === "Cloud & Infrastructure" &&
            cloudInfrastructureValue === "ec2" && (
              <AutomationEC2Datatable
                selectedApplicationName={selectedApplicationName}
                cloudInfrastructureSelectedValue={cloudInfrastructureValue}
                resourceHealthData={resourceHealthApiData}
                allAppName={applist}
                setSelectedApplicationName={updateSelectedAppName}
              />
            )}
        </div>

        {/* Snooze Alarm */}
        {showSnoozeAlarm && (
          <SnoozeAlarm
            application={selectedApplicationName}
            closeSnoozeParent={closeSnoozeCallback}
          />
        )}
      </Col>
    </Row>
  );
};

export default AutomationCloudEyeMonitor;
